import { spawn, all } from 'redux-saga/effects';
import authSagas from 'store/sagas/auth.sagas';
import conversationSagas from 'store/sagas/conversation.sagas';
import generalSagas from 'store/sagas/general.sagas';

export default function* rootSaga() {
  yield all([spawn(authSagas)]);
  yield all([spawn(conversationSagas)]);
  yield all([spawn(generalSagas)]);
}
