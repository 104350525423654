import React, { FunctionComponent } from 'react';
import Styles from 'components/checkBox/CheckBox.module.scss';

interface CheckBoxProps {
  id: string;
  label?: string;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
}
const CheckBox: FunctionComponent<CheckBoxProps> = (props: CheckBoxProps) => {
  const { id, label, handleChange } = props;
  return (
    <div className={Styles.formGroup}>
      <input type="checkbox" id={id} onChange={handleChange} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
export default CheckBox;
