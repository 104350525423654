import React, { FunctionComponent } from 'react';
import ComponentStyle from 'components/utils/errorMessage/TextErrorMessage.module.css';

interface InputProps {
  message: string;
  cssClass?: string;
}

const TextErrorMessage: FunctionComponent<InputProps> = (props: InputProps) => {
  const { message, cssClass } = props;
  return <h5 className={`${ComponentStyle.errorMessage} ${cssClass}`}>{message}</h5>;
};

export default TextErrorMessage;
