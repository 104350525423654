import React, { FunctionComponent, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import Styles from 'views/register//Register.module.scss';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { validateEmail, validateName } from 'helpers/utils';

interface RegisterProps {
  isLoading: boolean;
}

const Register: FunctionComponent<RegisterProps> = (props: RegisterProps) => {
  const { isLoading } = props;
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [requiredField, setRequiredField] = useState<string[]>([]);
  const userObj: any = {name: '', email: '', password: '', profile_pic: null};
  const history = useHistory();
  const location = useLocation();
  const handleSignUp = () => {
    const errors: string[] = [];
    if (name.length === 0) errors.push('Name');
    if (password.length === 0) errors.push(' Password');
    if (email.length === 0) errors.push(' Email');
    if (errors.length > 0) {
      setRequiredField(errors);
      return;
    } else {
      setRequiredField([]);
    }

    if (validateName(name)) {
      setNameError(false);
    } else {
      setNameError(true);
      return;
    }

    if (validateEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
      return;
    }

    userObj.name = name;
    userObj.email = email;
    userObj.password = password;
    if (location.search !== '') {
      const token = location.search.split("=");
      userObj.token = token[1]
    }
    localStorage.setItem('userObj', JSON.stringify(userObj));
    setRequiredField([]);
    history.push('/registration/step-two');
  };

  return (
    <>
      <div className={Styles.customContainer}>
        <div className={Styles.leftSectionWrapper}>
          <LoginLeftSection />
        </div>
        <div className={Styles.rightSectionWrapper}>
          <div className={Styles.loginArea}>
            <h4 className={Styles.accountHeading}>Create Account</h4>
            <p className={`mb-0 ${Styles.profileTitle}`}>Create a free profile in 3 easy steps to get started.</p>
            <div className="mt-40">
              <h5 className="fontTwenty font-weight-500 text-black text-uppercase">Step 1</h5>
              <p className={`mb-0 ${Styles.profileTitle}`}>Please provide your details</p>
            </div>
            <div className={Styles.formWrapper}>
              <div className="form-group">
                <InputField handleChange={setName} type="text" placeholder="Full Name" />
                {nameError && <p className={Styles.error}>Name should only contain latter</p>}
              </div>
              <div className="form-group">
                <InputField
                  handleChange={setEmail}
                  type="email"
                  placeholder="Email Address"
                />
                {emailError && <p className={Styles.error}>Invalid Email</p>}
              </div>
              <div className="form-group">
                <InputField
                  handleChange={setPassword}
                  type="password"
                  placeholder="Password"
                />
              </div>
              {requiredField.length > 0 && <p className={Styles.error}>{`Please Enter ${requiredField}`}</p>}
            </div>
            <p className={`mb-0 fontFifteen ${Styles.signingUpText}`}>
              By signing up, you agree to our &nbsp;
              <a
                href="https://soundbirdapp.com/terms.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue display-inline-block text-decoration-none fontFifteen font-weight-500"
              >
                Terms of Services
              </a>
              &nbsp; and &nbsp; <br />
              <a
                href="https://soundbirdapp.com/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue display-inline-block text-decoration-none fontFifteen font-weight-500"
              >
                Privacy Policy
              </a>
            </p>
            <div className={`text-center w-100 ${Styles.registerMarginBLock}`}>
              <Button showLoading={isLoading} clickHandler={handleSignUp} label="Sign Up" />
            </div>
            <p className="fontFifteen font-weight-normal text-black">
              Already have an account? &nbsp;
              <Link
                to="/login"
                className="text-blue display-inline-block text-decoration-none fontFifteen font-weight-bolder"
              >
                LOG IN
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
