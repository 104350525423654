import React, { FunctionComponent } from 'react';
import Styles from './RadioButton.module.scss';

interface RadioButtonProps {
  name: string;
  label?: string;
  checked?: boolean;
  value?: string;
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

const RadioButton: FunctionComponent<RadioButtonProps> = (props: RadioButtonProps) => {
  const { name, label, checked, value, handleChange } = props;
  const id = Math.random().toString(4).substring(1, 4);
  return (
    <>
      <div className={Styles.radio}>
        <input type="radio" name={name} value={value} id={id} onChange={handleChange} defaultChecked={checked} />
        <label htmlFor={id} className={`m-0 ${Styles.radioLabel}`}>
          {label}
        </label>
      </div>
    </>
  );
};

export default RadioButton;
