import { connect } from 'react-redux';
import { User } from 'modules/user/types';
import { getUser, getLoading, updateProfile } from 'store/selector/auth.selector';
import { updateProfileRequest, deleteProfileRequest } from 'store/actions/auth.actions';
import Profile from 'views/profile/Profile';

const mapDispatchToProps = {
  updateProfile: updateProfileRequest,
  deleteProfile: deleteProfileRequest,
};

const mapStateToProps = (state: User) => {
  const user = getUser(state);
  const loading = getLoading(state);
  const response = updateProfile(state);
  return { user, loading, response };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
