import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

const PrivateRoute = ({ component, path }: any) => {
  const user = getItemFromLocalStorage('user') || {};
  if (!user.access) {
    localStorage.removeItem('user');
    localStorage.clear();
    return <Redirect exact to={{ pathname: '/login' }} />;
  }
  if (user.access) {
    return <Route exact path={path} component={component} />;
  }
  return <Redirect exact to={{ pathname: '/login' }} />;
};

export default PrivateRoute;
