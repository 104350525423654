import React, { FunctionComponent, useState } from 'react';
import Button from 'components/button/Button';
import Styles from 'components/dateFormat/DateFormat.module.scss';
import RadioButton from 'components/button/radioButton/RadioButton';
import { date } from 'helpers/data/dateAndTime';

interface DateFormatProps {
  handleClose: Function;
  title: string;
}

const DateFormat: FunctionComponent<DateFormatProps> = (props: DateFormatProps) => {
  const { handleClose, title } = props;
  const [value, setValue] = useState('');
  const handleDone = () => handleClose(value);
  const handleChange = (event: any) => {
    setValue(event.target.value)
  };
  return (
    <div className={Styles.dateFormatWrapper}>
      <h4 className="fontTwenty font0weight-bolder text-center w-100 text-black">{title}</h4>
      {date.map((d, index) => (
        <div className="d-flex justify-content-between mt-30" key={index}>
          <div className={Styles.dateFormatInner}>
            <p className="mb-0 fontSixteen poppins-regular text-black">{d.format}</p>
            <span className="fontThirteen poppins-regular input-color">{d.eg}</span>
          </div>
          <RadioButton name="date_format" value={d.value} handleChange={handleChange} />
        </div>
      ))}
      <div className="w-100 text-center mt-50">
        <Button cssClass={Styles.btnWidth} label="Done" clickHandler={handleDone} />
      </div>
    </div>
  );
};
export default DateFormat;
