import React, { FC } from 'react';
import Styles from 'components/lightBox/LightBox.module.scss';
// import ReactImageVideoLightBox from 'react-image-video-lightbox';

interface LightBoxProps {
  data: any;
  index: number;
  closeDialog: Function;
}

const LightBox: FC<LightBoxProps> = (props: LightBoxProps) => {
  const { data, index, closeDialog } = props;
  console.log(data, index, closeDialog);
  return (
    <div className={`${Styles.wrapper} lightBoxWrapper `}>
      {/*<ReactImageVideoLightBox*/}
      {/*  startIndex={index}*/}
      {/*  data={data}*/}
      {/*  showResourceCount*/}
      {/*  onCloseCallback={closeDialog}*/}
      {/*  onNavigationCallback={(i: any) => console.log(`Current index: ${i}`)}*/}
      {/*/>*/}
    </div>
  );
};

export default LightBox;
