import React, { FunctionComponent, useState } from 'react';
import Button from 'components/button/Button';
import InputField from 'components/textField/InputField';
import { Link } from 'react-router-dom';
import Styles from 'views/register//Register.module.scss';
import api from 'store/services/auth.services';
import { checkEmail } from 'utils/FormValidation';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { LOADER_STATUS } from 'store/constants';

interface ForgetParams {}
const ForgetPassword: FunctionComponent<ForgetParams> = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [emailExist] = useState<number>(LOADER_STATUS.NA);

  const handleRequest = async () => {
    if (checkEmail(email) !== '') {
      setEmailError(checkEmail(email));
      return;
    } else {
      setEmailError('');
    }
    if (emailError.length > 0 || email.length === 0) {
      return;
    }
    try {
      setIsLoading(true);
      await api.forgetPassword({ email }).then((res) => {
        if (res) {
          setIsError(false);
          setResponse(res.message);
          setIsLoading(false);
          setEmail('');
        }
      });
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setError(JSON.parse(err.message).message);
    }
  };

  return (
    <div className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h4 className={Styles.accountHeading}>Forget your account password</h4>
          <div className={`mb-0 ${Styles.formWrapper}`}>
            <div className="form-group">
              <InputField
                errorMessage={emailExist === 0 ? 'Email Not Exists' : emailError}
                // handleKeyDown={handleKeyDownEvent}
                handleChange={setEmail}
                type="email"
                placeholder="Email Address"
              />
              {isError && <p className={Styles.error}>{error}</p>}
              {response.length > 0 && <p className="mb-0" style={{color: 'green', textAlign: 'left'}}>{response}</p>}
            </div>
          </div>
          <div className={`text-center ${Styles.signUpMarginBLock}`}>
            <Button clickHandler={handleRequest} showLoading={isLoading} label="Send Code" />
          </div>
          <p className="fontFifteen font-weight-normal text-black">
            Remember your password? &nbsp;
            <Link
              to="/login"
              className="text-blue text-decoration-none display-inline-block fontFifteen font-weight-bolder"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
