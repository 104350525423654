import React, { FunctionComponent, useState } from 'react';
import Button from 'components/button/Button';
import Styles from 'components/timeFormat/TimeFormat.module.scss';
import RadioButton from 'components/button/radioButton/RadioButton';
import { time } from 'helpers/data/dateAndTime';

interface TimeFormatProps {
  handleClose: Function;
  title: string;
}
const TimeFormat: FunctionComponent<TimeFormatProps> = (props: TimeFormatProps) => {
  const { handleClose, title } = props;
  const [value, setValue] = useState('');
  const handleDone = () => handleClose(value);
  const handleChange = (event: any) => {
    setValue(event.target.value)
  };
  return (
    <div className={Styles.timeFormatWrapper}>
      <h4 className="fontTwenty font-weight-bolder text-center w-100 text-black">{title}</h4>
      {time.map((t, index) => (
        <div className="d-flex justify-content-between mt-30" key={index}>
          <div className={Styles.timeFormatInner}>
            <p className="mb-0 fontSixteen poppins-regular text-black">{t.format}</p>
            <span className="fontThirteen poppins-regular input-color">{t.detail}</span>
          </div>
          <RadioButton name="time_format" value={t.value} handleChange={handleChange} />
        </div>
      ))}
      <div className="w-100 text-center mt-50">
        <Button cssClass={Styles.btnWidth} label="Done" clickHandler={handleDone} />
      </div>
    </div>
  );
};
export default TimeFormat;
