import moment from 'moment';

export const formatMonthDate = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format('MMMM DD');
};

export const formatDate = (date: string, format: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format(format);
};

export const isValidFile = (file: File, type: string) => {
  const fileName = file.name;

  const exts = type === 'image' ? ['png', 'jpg', 'jpeg', 'gif'] : ['pdf', 'doc', 'docx'];

  if (fileName) {
    let getExt = fileName.split('.');
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return type === 'image' ? 'only image files are allowed' : 'only pdf files are allowed';
    }

    if (file.size / 1024 / 1024 > 2) {
      return 'max. 2MB file size allow';
    }

    return '';
  }
  return '';
};

export const getCurrentDate = () => `${formatAMPM(new Date())} ${moment().format('YYYY-MM-DD')} `;

export const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const latRad1 = toRad(lat1);
  const latRad2 = toRad(lat2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latRad1) * Math.cos(latRad2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return Math.floor(d);
};

export const setTime = (startTime: any) => {
  let dateDiff = (startTime - new Date().getTime()) / 1000;
  let countdownTimerText = '';
  if (Math.abs(dateDiff) <= 60 * 60) {
    const timer = setInterval(() => {
      dateDiff = (startTime - new Date().getTime()) / 1000;
      if (dateDiff <= 0) {
        clearInterval(timer);
        return;
      }
      if (dateDiff < 0) {
        countdownTimerText = '-';
        dateDiff *= -1;
      }

      const ss = Math.floor(dateDiff % 60);
      dateDiff /= 60;
      const mm = Math.floor(dateDiff % 60);

      countdownTimerText += `${mm} M   ${ss} S`;
    }, 1000);
  } else {
    // let countdownTimerText = '';
    if (dateDiff < 0) {
      // countdownTimerText = '-';
      dateDiff *= -1;
    }

    dateDiff /= 60;
    const mm = Math.floor(dateDiff % 60);
    dateDiff /= 60;
    const hh = Math.floor(dateDiff % 24);
    dateDiff /= 24;
    const dd = Math.floor(dateDiff);

    if (dd > 0) countdownTimerText = `${dd} D ${hh} H`;
    else countdownTimerText = `${hh} H ${mm} M`;
  }
  console.log('Countdown : ', countdownTimerText);
  return countdownTimerText;
};

export const formatAMPM = (date: any) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  seconds = seconds < 10 ? '0'+seconds : seconds;
  return hours + ':' + minutes + ':' + seconds + ' ' + ampm;
}

export const getDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2-lat1);  // deg2rad below
  let dLon = deg2rad(lon2-lon1);
  let a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
  ;
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  let d = R * c; // Distance in km
  return d.toFixed(2);
}

const deg2rad = (deg: any) => {
  return deg * (Math.PI/180)
}

export const randomString = () => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( let i = 0; i < 15; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const toRad = (value: any) => (value * Math.PI) / 180;

export const getErrorMessage = (error: any) => {
  const err = JSON.parse(error);
  if (err.detail) {
    return err.detail;
  }
  if (err.error) {
    return err.error;
  }
  if (err.nonFieldErrors) {
    return err.nonFieldErrors[0];
  }
  if (err.email) {
    return err.email[0];
  }
  return '';
};

export const broadCastStorage = {
  save: (obj: any) => localStorage.setItem('broadCast', JSON.stringify(obj)),
  get: () => {
    const obj = localStorage.getItem('broadCast') || null;
    if (obj) return JSON.parse(obj);
    return null;
  },
  clear: () => localStorage.removeItem('broadCast'),
};

export const validateName = (name: any) => {
  return String(name)
    .toLowerCase()
    .match(/^[a-zA-Z\s]+(?:-[a-zA-Z]+)*$/);
};

export const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (phone: any) => {
  return String(phone)
    .toLowerCase()
    .match(
    /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
    );
};
