import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface DashboardLinkProps {
  iconClass: string;
  cssClass?: string;
}

const DashboardLink: FunctionComponent<DashboardLinkProps> = (props: DashboardLinkProps) => {
  const { iconClass, cssClass } = props;
  return (
    <div className={cssClass}>
      <Link
        to="/conversations-nearby"
        className="text-uppercase text-decoration-none fontSixteen text-blue font-weight-bold"
      >
        <i className={`pr-2 ${iconClass}`} />
        <span className="mb-0">BACK TO DASHBOARD</span>
      </Link>
    </div>
  );
};
export default DashboardLink;
