import React, { FunctionComponent } from 'react';
import { Spinner } from 'react-bootstrap';

interface LoaderProps {
  aligned?: boolean;
  cssClass?: string;
  spinner?: string;
}
const Loader: FunctionComponent<LoaderProps> = (props: LoaderProps) => {
  const { cssClass, aligned, spinner } = props;
  return (
    <>
      {aligned && aligned ? (
        <div className={`spinner-loader ${spinner}`}>
          <Spinner as="span" className={cssClass} animation="border" size="sm" role="status" aria-hidden="true" />
        </div>
      ) : (
        <Spinner
          as="span"
          className="buttonSpinnerPosition"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};
export default Loader;
