import Login from 'views/login';
import TermAndConditions from 'views/termAndConditions/TermAndConditions';
import Register from 'views/register';
import ForgetPassword from 'views/forgetPassword/ForgetPassword';
import RestorePassword from 'views/forgetPassword/RestorePassword';
import SplashScreen from 'views/splashScreen/SplashScreen';
import About from 'views/about/About';
import EmailVerification from 'views/register/emailVerification/index';
import OtpVerification from 'views/register/otpVerification/index';
import UploadProfile from 'views/register/uploadProfile/index';
import StepFour from 'views/register/stepFour';

export default [
  {
    path: '/about',
    component: About,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgetPassword,
  },
  {
    path: '/registration/step-two',
    component: EmailVerification,
  },
  {
    path: '/registration/otp',
    component: OtpVerification,
  },
  {
    path: '/registration/step-three',
    component: UploadProfile,
  },
  {
    path: '/registration/step-four',
    component: StepFour,
  },
  {
    path: '/registration',
    component: Register,
  },
  {
    path: '/termandservices',
    component: TermAndConditions,
  },
  {
    path: '/restorePassword',
    component: RestorePassword,
  },
  {
    path: '/restore-password/restore/',
    component: RestorePassword,
  },
  {
    path: '/callback',
    component: Register,
  },
  {
    path: '/',
    component: SplashScreen,
  },
];
