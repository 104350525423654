import React, { FunctionComponent, useState } from 'react';
import Styles from 'components/textArea/TextArea.module.scss';

interface TextAreaProps {
  placeholder?: string;
  cssClass?: string;
  handleValue: Function;
}
const TextArea: FunctionComponent<TextAreaProps> = (props: TextAreaProps) => {
  const { placeholder, cssClass, handleValue } = props;
  const handleChange = (event: any) => {
    handleValue(event.target.value);
  };
  return (
    <textarea onChange={handleChange} placeholder={placeholder} className={`poppins-regular fontSixteen input-color bg-grey ${Styles.textArea} ${cssClass}`}>

    </textarea>
  );
};
export default TextArea;
