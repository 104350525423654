import React, {FC, useEffect, useState} from 'react';
import closeIcon from 'assets/svg/close-icon.svg';
import Styles from 'components/chatContent/messageThread/MessageThread.module.scss';
import ChatFooter from 'components/chatContent/chatFooter/ChatFooter';
import MessageBox from 'components/chatContent/messageBox/MessageBox';
import { IMAGE_URL } from 'store/services/URL';
import {getImages} from "../../../store/services/firebase-broadcast";

interface MessageThreadProps {
  hideReplies: Function;
  item: any;
  selected: any;
  actions: boolean;
}

const MessageThread: FC<MessageThreadProps> = (props: MessageThreadProps) => {
  const { hideReplies, item, selected, actions } = props;
  const [typing, setTyping] = useState(false);
  const [threads, setThreads] = useState([]);
  const [attachment, setAttachment] = useState('');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const handleShowReplies = () => hideReplies(false);

  const getTime = (time: string) => {
    const timeStr = time.split(':');
    return timeStr[0] + ':' + timeStr[1];
  };

  useEffect(() => {
    if (item.hasOwnProperty('threads')) {
      setThreads(item.threads);
    }
  }, [item]);

  useEffect(() => {
    if (item.hasOwnProperty('attachments') && item.attachments[0].id !== '') {
      getImages(selected.pk, item.attachments[0].id).then((e) => setAttachment(e[0]));
    }
  }, []);

  return (
    <div className={Styles.messageThreadWrapper}>
      <div className={Styles.header}>
        <h6 className="my-0 font-weight-normal fontSixteen text-black">Message thread</h6>
        <div onClick={handleShowReplies} onKeyPress={handleShowReplies} role="button" tabIndex={0}>
          <img src={closeIcon} alt="close icon" />
        </div>
      </div>
      <div className={`customScrollBar ${Styles.BodyContent}`}>
        <div className={`mb-0 ${Styles.message} ${Styles.borderBottom}`}>
          <img src={`${IMAGE_URL}${item.sender.image}`} alt="user" className={Styles.userImage} />
          <div className={Styles.userDetails}>
            <div className={Styles.detailsInner}>
              <h5 className="my-0">{item.sender.name}</h5>
              <span className="fontFifteen font-weight-normal input-color">{getTime(item.time)}</span>
              {item.sender.isHost && <span className={`badge text-white ${Styles.hostBadge}`}>Host</span>}
            </div>
            <p className="fontFifteen text-black font-weight-normal mb-0">{item.message}</p>
            {attachment && (
              <div className="d-flex flex-column">
                <span className={Styles.uploadedImageDetails}>{item.attachments[0].name}</span>
                <img src={attachment} alt="attachment" className={Styles.uploadedImage} />
              </div>
            )}
          </div>
        </div>
        <p className="fontFourteen text-black font-weight-600 my-3">{threads.length} REPLIES</p>
        {threads.map((i, index) => (
          <MessageBox item={i} actions={actions} key={index} type="threads" />
        ))}
      </div>
      {typing ?
        <span className={`px-3 fontFifteen input-color font-weight-normal ${Styles.typingUser}`}>{`${user.name} is typing…`}</span>
        : (<div className="pb-2">&nbsp;</div>)}
        <ChatFooter selected={selected} isThread parentMessage={item} typing={setTyping} />
    </div>
  );
};
export default MessageThread;
