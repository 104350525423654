import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

const PublicRoute = ({ component, path }: any) => {
  const user = getItemFromLocalStorage('user') || {};
  const { access } = user;
  return access ? (
    <Redirect to="/conversations-nearby" />
  ) : (
    <Route exact path={path} component={component} />
  );
};

export default PublicRoute;
