const parseError = (error: any) => {
  let message = '';
  if (error.response) {
    message = JSON.stringify(error.response.data);
  } else if (error.request) {
    message = 'Network Error!';
  } else {
    message = error.message;
  }
  throw new Error(message);
};

export default parseError;
