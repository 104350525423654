import React, { FunctionComponent } from 'react';
import Styles from 'components/button/switchButton/SwitchButton.module.scss';

interface SwitchButtonProps {
  isChecked?: boolean;
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}
const SwitchButton: FunctionComponent<SwitchButtonProps> = (props: SwitchButtonProps) => {
  const { isChecked, handleChange } = props;
  return (
    <label className={Styles.switch}>
      <input type="checkbox" checked={isChecked} onChange={handleChange} />
      <span className={`${Styles.slider} ${Styles.round}`} />
    </label>
  );
};

export default SwitchButton;
